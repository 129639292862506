import React from 'react';
import ReactDOM from 'react-dom/client';
import Header from './template/Header';
import Footer from './template/Footer';
import Home from './screens/Home';
import Register from './screens/Register';
import './Bootstrap/bootstrap.min.css';
import './index.css';
import axios from "axios";
import {Helmet} from "react-helmet";
import {BrowserRouter as Router,Routes,Route,Link,useParams} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
      <Router>
          <Helmet>
            <title>Larin - O seu lar in..crível</title>
            <meta name="robots" content="index,follow,noodp"/>
            <meta content="pt" name="language"/>
            <meta property="og:type" content="website"/>
            <meta charset="utf-8"/>
            <meta property="og:locale"/>
            <meta property="og:site_name" content="Larin, o espaço digital para lares, centros de dia, serviços e cuidados para a terceira idade e idosos."/>
            <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
            <meta property="og:title" content="Larin, a comunidade digital para pais e filhos para ajudar idosos."/>
            <meta property="og:url" content="https://www.larin.pt/"/>
            <link rel="canonical" href="https://www.larin.pt/"></link>
          </Helmet>
          <Routes>
            <Route exact path="/" element={
              <div>
                <Header />
                <Home />
                <Footer />
              </div>
            } />
            <Route exact path="/register" element={
              <div>
                <Register />
              </div>
            } />
          </Routes>
        </Router>
    );