import '../index.css';
import logo from '../images/logo.svg';
import logoFooter from '../images/larin-icon.png';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Accordion from "react-bootstrap/Accordion";
import arrowNav from '../images/arrow-navigation.svg';
import {
	Link
  } from "react-router-dom";

function Footer() {
  return (
    <div>
      {/*
      <hr className='mt-60' />
      <div className="navbar-footer hide-mobile">
        <Container>
          <Row>
            <Col md={3}>
              <div className="select-wrapper">
                <select className='select'>
                  <option value="PT">Português</option>
                </select>
                <img src={arrowNav}/>
              </div>
            </Col>
            <Col md={3}>
              <h5>Larin</h5>
              <ul className="footer-navbar">
                <li className="footer-navbar-item"><Link to="#">Sobre o Larin</Link></li>
                <li className="footer-navbar-item"><Link to="#">Testemunhos</Link></li>
                <li className="footer-navbar-item"><Link to="#">Dicas</Link></li>
                <li className="footer-navbar-item"><Link to="https://larin.pt/revista">Revista</Link></li>
                <li className="footer-navbar-item"><Link to="#">Centro de imprensa</Link></li>
                <li className="footer-navbar-item"><Link to="#">Contacte-nos</Link></li>
              </ul>
            </Col>
            <Col md={3}>
              <h5>Precisa de ajuda</h5>
              <ul className="footer-navbar">
                <li className="footer-navbar-item"><Link to="#">Perguntas frequentes</Link></li>
                <li className="footer-navbar-item"><Link to="#">Regiões</Link></li>
                <li className="footer-navbar-item"><Link to="#">Cidades</Link></li>
                <li className="footer-navbar-item"><Link to="#">Centros de dia</Link></li>
                <li className="footer-navbar-item"><Link to="#">Lares residenciais</Link></li>
                <li className="footer-navbar-item"><Link to="#">Apoio domiciliário</Link></li>
                <li className="footer-navbar-item"><Link to="#">Cuidados continuados</Link></li>
              </ul>
            </Col>
            <Col md={3}>
              <h5>Adicionar o seu espaço</h5>
              <ul className="footer-navbar">
                <li className="footer-navbar-item"><Link to="#">Porque aderir ao Larin</Link></li>
                <li className="footer-navbar-item"><Link to="#">Hospitalidade</Link></li>
                <li className="footer-navbar-item"><Link to="#">Perguntas frequentes</Link></li>
                <li className="footer-navbar-item"><Link to="#">Publicidade</Link></li>
              </ul>
            </Col>
            </Row>
           
        </Container>
      </div>
      
      <div className="navbar-footer show-mobile">
        <Container>
              <div className="select-wrapper">
                <select className='select'>
                  <option value="PT">Português</option>
                </select>
                <img src={arrowNav}/>
              </div>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header><h5>Larin</h5></Accordion.Header>
                  <Accordion.Body>
                    <ul className="footer-navbar">
                      <li className="footer-navbar-item"><Link to="#">Sobre o Larin</Link></li>
                      <li className="footer-navbar-item"><Link to="#">Testemunhos</Link></li>
                      <li className="footer-navbar-item"><Link to="#">Dicas</Link></li>
                      <li className="footer-navbar-item"><Link to="https://larin.pt/revista">Revista</Link></li>
                      <li className="footer-navbar-item"><Link to="#">Centro de imprensa</Link></li>
                      <li className="footer-navbar-item"><Link to="#">Contacte-nos</Link></li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header><h5>Precisa de ajuda</h5></Accordion.Header>
                  <Accordion.Body>
                    <ul className="footer-navbar">
                      <li className="footer-navbar-item"><Link to="#">Perguntas frequentes</Link></li>
                      <li className="footer-navbar-item"><Link to="#">Regiões</Link></li>
                      <li className="footer-navbar-item"><Link to="#">Cidades</Link></li>
                      <li className="footer-navbar-item"><Link to="#">Centros de dia</Link></li>
                      <li className="footer-navbar-item"><Link to="#">Lares residenciais</Link></li>
                      <li className="footer-navbar-item"><Link to="#">Apoio domiciliário</Link></li>
                      <li className="footer-navbar-item"><Link to="#">Cuidados continuados</Link></li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header><h5>Adicionar o seu espaço</h5></Accordion.Header>
                  <Accordion.Body>
                    <ul className="footer-navbar">
                      <li className="footer-navbar-item"><Link to="#">Porque aderir ao Larin</Link></li>
                      <li className="footer-navbar-item"><Link to="#">Hospitalidade</Link></li>
                      <li className="footer-navbar-item"><Link to="#">Perguntas frequentes</Link></li>
                      <li className="footer-navbar-item"><Link to="#">Publicidade</Link></li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
        </Container>
      </div>
      */}
      <div className='footer hide-mobile'>
        <Container>
        <hr style={{paddingTop:"15px"}}/>
         <Row>
            <Col md={6}>
              <div className="footer-bottom">
                <img src={logoFooter} className="footer-logo" alt="logo" />
                <p> © Larin </p>
              </div>
            </Col>
            <Col md={6}>
            <ul className="footer-navbar2">
                <li className="footer-navbar-item2"><Link to="https://larin.pt/pagina/termos-e-condicoes">Termos e condições</Link></li>
                <li className="footer-navbar-item2"><Link to="https://larin.pt/pagina/politica-privacidade">Privacidade</Link></li>
                <li className="footer-navbar-item2"><Link to="https://larin.pt/revista">Revista</Link></li>
                {/*
                <li className="footer-navbar-item2"><Link to="#">Site map</Link></li>
                */}
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='footer show-mobile'>
        <Container>
          <div className="footer-navbar-mobile">
            <Link to="https://larin.pt/pagina/termos-e-condicoes">Termos e condições</Link> <span>•</span> <Link to="https://larin.pt/pagina/politica-privacidade">Privacidade</Link> <span>•</span> <Link to="https://larin.pt/revista">Revista</Link>
            {/*
            <span>•</span> <Link to="#">Site map</Link>
            */}
          </div>
          <div className="footer-bottom">
            <img src={logoFooter} className="footer-logo" alt="logo" />
            <p> © Larin </p>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default Footer;
